import "core-js/stable";
import "regenerator-runtime/runtime";
import accounting from "coupa-common-js/core/utils/accounting";
import "../src/tour/tour";

if (application_direction === "rtl") {
  import("coupa-common-js/styles/rtl/css/coupabase.min");
  import("coupa-common-js/styles/rtl/css/coupacomponents.min");
  import("coupa-common-js/styles/rtl/css/enterprisecomponents.min");
} else {
  import("coupa-common-js/styles/css/coupabase.min");
  import("coupa-common-js/styles/css/coupacomponents.min");
  import("coupa-common-js/styles/css/enterprisecomponents.min");
}

window.accounting = accounting;
