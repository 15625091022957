import { element } from "prop-types";
import showTour, { PageKey } from "./showTour";

const TOUR_PAGE = CSN.tour_page as string;

switch (TOUR_PAGE) {
  case "invoices":
    window.addEventListener("DOMContentLoaded", () => {
      const ele = document.querySelector("#customer_select_row");
      if (ele != null && !CSN.completed_invoice_tour) {
        showTour("invoices");
      }
    });
    break;

  case "orders":
    if (!CSN.completed_po_tour) {
      showTour("orders");
    }
    break;
}

(window as any).Tour = {
  show: () => {
    showTour(TOUR_PAGE as PageKey);
  },
};
