import { AxiosAPIUtils } from "coupa-common-js";
const IntroJS = (window as any).introJs;

const PAGES = {
  invoices: {
    postDataKey: "invoice",
    steps: [
      {
        element: "#customer_select_row",
        introElement: "#inv-step1-tmpl",
      },
    ],
  },
  orders: {
    postDataKey: "po",
    steps: [
      {
        element: "#customer_select_row",
        introElement: "#po-step1-tmpl",
      },
    ],
  },
};

export type PageKey = keyof typeof PAGES;

function showTour(pageKey: PageKey) {
  const introJS = IntroJS();

  const page = PAGES[pageKey];

  if (!page) {
    console.error(`Tour page "${pageKey}" does not exist`);
    return;
  }

  introJS.setOptions({
    exitOnOverlayClick: false,
    showStepNumbers: false,
    showBullets: false,
    exitOnEsc: true,
    tooltipClass: "csn-tour",
    nextLabel: I18n.t("defaults.got_it"),
    prevLabel: I18n.t("defaults.previous"),
    skipLabel: I18n.t("defaults.close"),
    doneLabel: I18n.t("defaults.got_it"),
    steps: page.steps.map((step) => ({
      element: step.element,
      intro: document.querySelector(step.introElement).innerHTML,
      position: "bottom",
      nextLabel: I18n.t("defaults.got_it"),
      prevLabel: I18n.t("defaults.previous"),
      skipLabel: I18n.t("defaults.close"),
    })),
  });

  introJS.oncomplete(() => {
    const postData = {
      [page.postDataKey]: true,
    };

    AxiosAPIUtils.post("/users/completed_tour", postData);
  });

  introJS.start();
}

export default showTour;
